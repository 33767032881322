<template>
  <b-card v-if="!invalid" class="shadow" :key="tick">
    <template #header>
      <h3 class="display-4">Unser Angebot</h3>
      <p class="lead">Guten Tag {{ (customer.data.salutation === 'MR') ? 'Herr' : 'Frau' }} {{ customer.data.lastName }} [{{ customer.data.companyName }}]</p>
      <p>Wir freuen uns, Ihnen dieses Angebot unterbreiten zu dürfen! Um das Angebot anzunehmen, klicken Sie einfach unten auf "Zur Kasse". Nach Vervollständigung Ihrer Daten und Angabe der Bankverbindung erhalten Sie umgehend die Zugangsdaten und alle Downloads.</p>
      <p v-if="false">Sollten Sie Fragen zu diesem Angebot haben, bitten Sie einfach um Rückruf mit dem Button  unten auf dieser Seite. Haben Sie dieses Angebot für Ihren Vorgesetzten erstellen lassen? Leiten Sie dieses Angebot einfach per Mail weiter!</p>
    </template>
    <b-card-text>
      <div class="row text-left ">
        <div class="col-lg-6 order-lg-first">
          <!----
          <h5>Kontaktdaten</h5>
          <b-table stacked :items="[customer.data]" :fields="[{key: 'firstName', label: 'Vorname'},{key: 'lastName', label: 'Nachname'},{key: 'companyName', label: 'Firma'},{key: 'phone1', label: 'Telefon'},{key: 'email', label: 'E-Mail'}]"></b-table>
          ---->
          <div v-if="discounted && !couponIsLoading">
            <h5 class="mt-3" v-if="couponValidationResult.valid">Rabattinformationen</h5>

            <b-table v-if="couponValidationResult.valid" stacked :items="[couponValidationResult.couponConfig]" :fields="[
          {key: 'validTo', label: 'Angebot gültig bis'},
          {key: 'startCredits', label: 'Startguthaben'}
          ]">
              <template #cell(validTo)="data">
                {{ new Date(data.item.validTo).toLocaleDateString("de-DE",{ year: 'numeric', month: 'long', day: 'numeric' })  }}
              </template>
              <template #cell(startCredits)="data">
                {{ data.item.startCredits  }} {{ data.item.startCreditsCurrency }}
              </template>
            </b-table>

            <b-table stacked :items="[couponValidationResult.discountConfig]" :fields="[
          {key: 'description', label: 'Beschreibung'},
          {key: 'discountPercent', label: 'Rabatt in %'},
          {key: 'validityPeriod', label: 'Rabattdauer'},
          {key: 'applyRecurrItems', label: 'Auf'},
          ]">
              <template #cell(validityPeriod)="data">
                {{ data.item.validityPeriod  }} {{ translations[data.item.validityUnit] }}
              </template>
              <template #cell(applyRecurrItems)="data">
                <ul>
                  <li v-if="data.item.applyRecurrItems"> Wiederkehrende Zahlungen</li>
                  <li v-if="data.item.applyOnetimeItems"> Einmal Zahlungen</li>
                  <li v-for="item,i in data.item.items" :key="i+'items21'">{{item.name}}</li>
                </ul>
              </template>
            </b-table>
          </div>

          <span v-if="couponValidationResult.valid">Der Rabatt wurde in dem Angebot bereits berücksichtigt.</span>


          <h5 class="mt-3">Vertragsdaten</h5>
          <b-table stacked :items="[contract]" :fields="[
          {key: 'recur', label: 'Abrechnungsintervall'},
          {key: 'termPeriod', label: 'Mindestlaufzeit'},
          {key: 'continuePeriod', label: 'Verlängerung nach Mindestlaufzeit'},
          {key: 'noticePeriod', label: 'Kündigungsfrist'}
          ]">
            <template #cell(recur)="data">
              {{ data.item.recur  }} {{ translations[data.item.recurUnit] }}
            </template>
            <template #cell(termPeriod)="data">
              {{ data.item.termPeriod  }} {{ translations[data.item.termUnit] }}
            </template>
            <template #cell(continuePeriod)="data">
              {{ data.item.continuePeriod  }} {{ translations[data.item.continueUnit] }}
            </template>
            <template #cell(noticePeriod)="data">
              {{ data.item.noticePeriod  }} {{ translations[data.item.noticeUnit] }}
            </template>
          </b-table>


          <b-button v-if="$store.getters['orderpage/getType'] === 'biyear'" variant="outline-primary" @click="changeTo('setMonthly')" block>Sie möchten lieber monatlich kündigen?</b-button>
          <b-button v-else variant="outline-primary" @click="changeTo('setBiyear')" block>Zurück zu 2-jähriger Vertragsdauer</b-button>

          <router-link to="/" class="btn btn-info btn-block">Neues Angebot erstellen</router-link>

        </div>
        <div class="col-lg-6 order-first">
          <PriceTable v-if="!priceIsLoading" :items="calculatedItems" :regular-items="calculatedRegularItems" title='Abo-Artikel <span class="text-muted">(wiederkehrende Zahlungen pro Monat)</span>' type="RECURRING" :discounted="couponValidationResult.discountConfig"/>
          <PriceTable v-if="!priceIsLoading" :items="calculatedItems" :regular-items="calculatedRegularItems" title="Einmalige Zahlungen" type="ONETIME" :discounted="couponValidationResult.discountConfig"/>

          <div class="text-right mb-5 text-muted">
          Betrag der ersten Rechnung: {{formatter.format(calculatedItems.amountFirstInvoice)}}
          </div>
        </div>
      </div>
    </b-card-text>
    <template #footer>
      <div class="row bg-white fixed-bottom p-4 shadow-lg">
        <div class="col text-left">
          <!---<b-button href="#" variant="primary" class="mr-2">Um Rückruf bitten</b-button> --->
          <!---<b-button href="#" variant="primary">Weiterleiten</b-button> --->
        </div>
        <div class="col text-right">
          <b-button href="#" @click.prevent="$router.push({name: 'order', params: {customer: customer, contract: contract, amountFirstInvoice: calculatedItems.amountFirstInvoice, calculatedItems: calculatedItems, calculatedRegularItems: calculatedRegularItems, couponValidationResult: couponValidationResult}})" variant="success" size="lg"><b-icon icon="cart-check" ></b-icon> Zur Kasse</b-button>
        </div>
      </div>
    </template>
  </b-card>
  <div v-else>
    <b-alert show>Das Angebot ist ungültig!</b-alert>
    <b-button @click="$router.push('/')" block variant="primary">Neues Angebot erstellen</b-button>
  </div>
</template>

<script>


  import PriceTable from "@/components/PriceTable";

  export default {
    name:"Offering",
    components:{PriceTable},
    data() {
      return {
        tick: 1,
        couponIsLoading: true,
        priceIsLoading: true,
        customer: null,
        contract: null,
        invalid: false,
        couponValidationResult: [],
        calculatedItems: [],
        calculatedRegularItems: [],
        translations: {
          WEEK: 'Woche(n)',
          YEAR: 'Jahr(e)',
          MONTH: 'Monat(e)'
        },
        formatter: new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }),
      }
    },
    created() {
      this.customer = JSON.parse(atob(decodeURIComponent(this.$route.params.customer)));
      this.contract = JSON.parse(atob(decodeURIComponent(this.$route.params.contract)));


        fetch('https://api.ipify.org?format=json',{
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
        }).then(this.handleResponse)
            .then(response=>{
              this.customer.data.ip = response.ip;
            }).catch(() => {
          fetch('https://ipapi.co/json/',{
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
          }).then(this.handleResponse)
              .then(response=>{
                this.customer.data.ip = response.ip;
              })
        })

      this.init();
    },
    computed: {
      orderItems(){
        return this.getOrderItems();
      },
      orderAddons(){
        return this.getOrderAddonItems();
      },
      maxAddon() {
        return this.getQuantity() - this.getAddonQuantity();
      },
      orderPage() {
        return this.$store.getters['orderpage/showOrderpageById'](this.$store.getters['orderpage/getCurrentOrderpage'])
      }
    },
    methods: {
      init() {
        this.priceIsLoading = true;
        this.couponIsLoading = true;
        this.calculateFakturiaPrice(this.getOrderItems().concat(this.getOrderAddonItems()),this.customer.coupon).then(resp =>{
          if (typeof resp.errorMessage !== 'undefined') {
            this.invalid = true
            console.log(resp.errorMessage)
          }
          this.calculatedItems = resp;
          this.priceIsLoading = false;
        })
        this.discounted = (this.customer.coupon !== '') ? true : false;

        if (this.discounted) {
          this.validateCoupon(this.customer.coupon).then( resp => {
                this.couponValidationResult = resp
                this.couponIsLoading = false;
              }
          )
        }
        this.calculateFakturiaPrice(this.getOrderItems().concat(this.getOrderAddonItems()),null).then(resp =>{
          if (typeof resp.errorMessage !== 'undefined') {
            this.invalid = true
            console.log(resp.errorMessage)
          }
          this.calculatedRegularItems = resp;
        })
      },
      changeToOppositeArticel(itemList, newItems) {
        itemList.forEach(item =>{
          item.item = newItems.find(el => el.item.customFields.opposite === item.item.itemNumber).item;
        })
      },
      calculateFakturiaPrice(items,couponCode) {
          return new Promise((resolve, reject) => {
            const header = this.authHeader();

            let formattedItems = []
            items.forEach(item =>{
              formattedItems.push({
                quantity:	item.quantity,
                itemType:	item.itemType,
                name:	item.item.name,
                itemNumber:	item.item.itemNumber,
                description:	item.item.description,
                unit:	item.item.unit
              })
            })


            header['Content-Type'] = 'application/json';
            console.log(this.customer.coupon)
            let senddata = {
              pageID: this.$store.getters['orderpage/getCurrentOrderpage'],
              payload: {
                country: 'de',
                customerType: 'BUSINESS',
                euTaxId:	'',
                currency:	'EUR',
                couponCode:	couponCode,
                items: formattedItems
              }
            }
            const requestOptions = {
              method: 'POST',
              headers: header,
              body: JSON.stringify(senddata)
            };

            fetch(process.env.VUE_APP_API_SERVER + `?function=FakturiaCalcPrice`, requestOptions)
                .then(this.handleResponse)
                .then(data => {
                  resolve(data)
                })
                .catch(error => {
                  reject(error)
                });
          })

      },
      validateCoupon(coupon) {
        return new Promise((resolve, reject) => {
          const header = this.authHeader();
          header['Content-Type'] = 'application/json';
          let senddata = {
            pageID: this.$store.getters['orderpage/getCurrentOrderpage'],
            payload: {
              couponCode:	coupon,
              projectShortName: process.env.VUE_APP_PROJECT_SHORTNAME
            }
          }
          const requestOptions = {
            method: 'POST',
            headers: header,
            body: JSON.stringify(senddata)
          };

          fetch(process.env.VUE_APP_API_SERVER + `?function=FakturiaValidateCoupon`, requestOptions)
              .then(this.handleResponse)
              .then(data => {
                resolve(data)
              })
              .catch(error => {
                reject(error)
              });
        })

      },
      itemTotalPrice(itemlist) {
        let sum = 0;
        itemlist.forEach(item => {
          sum += item.item.prices.filter(price => item.quantity >= price.minimumQuantity ).pop().cost*item.quantity
        })
        return sum;
      },
      getOrderItems() {
        return this.contract.itemList.filter(item => item.quantity > 0 );
      },
      getOrderAddonItems() {
        return this.contract.addonList;
      },
      getQuantity() {
        let out = 0;
        this.getOrderItems().forEach(item => {
          out += item.quantity
        })
        return out;
      },
      getAddonQuantity() {
        let out = 0;
        this.getOrderAddonItems().forEach(item => {
          out += item.quantity
        })
        return out;
      },
      isDateBeforeToday(date) {
        return new Date(date.toDateString()) < new Date(new Date().toDateString());
      },
      changeTo(type)
      {
        this.$store.dispatch('orderpage/'+type);
        let oldItemList = JSON.parse(JSON.stringify(this.contract.itemList));
        let oldAddonList = JSON.parse(JSON.stringify(this.contract.addonList));
        this.contract = JSON.parse(JSON.stringify(this.orderPage.contractTemplate));
        this.contract.itemList = oldItemList;
        this.contract.addonList = oldAddonList;
        this.changeToOppositeArticel(this.contract.itemList,this.orderPage.contractTemplate.itemList);

        this.init();
      }
    }
  }
</script>

