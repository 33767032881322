<template>
  <b-modal v-model="show" id="type-modal" body-class="text-center p-5" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc>

    <b-row>
      <b-col><h3 class="mb-5">
        Sind Sie ein gewerblicher Anbieter für Glücksspiele?
      </h3>
      </b-col>
    </b-row>
      <b-row>
        <b-col>
          <b-button @click="show=false" variant="primary">JA</b-button>
        </b-col>
        <b-col>
          <a href="https://socialguard.app/oasis/sperre/zu-oasis/" class="btn btn-primary">NEIN</a>
        </b-col>
      </b-row>
  </b-modal>
</template>
<script>
  export default {
    name:'ChooseBusinessType',
    data(){
      return {
        show: true
      }
    },
    created() {
      if (this.$route.name !== 'start') {
        this.show = false;
      }
    }
  }
</script>
