import Vue from 'vue';
import Vuex from 'vuex';

import { loading } from './loading.module';
import { orderpage} from "./orderpage.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    loading,
    orderpage
  }
});


