<template>
  <div id="app">
    <b-container>
      <main>
        <div class="py-5 text-center">
          <div class="row mb-4">
            <div class="col-4"><img class="d-block mx-auto" src="./assets/icon_new.png" alt="Socialguard Konfigurator" height="50"></div>
            <div class="col-8 align-middle"><h3>Konfigurator</h3></div>
          </div>
          <p class="lead">Schritt für Schritt!<br>In wenigen Minuten einsatzbereit!</p>
          <Transition>
            <router-view></router-view>
          </Transition>
          <p class="text-muted mt-5">© Meileh GmbH <a href="https://www.socialguard.app" target="_blank">Socialguard</a></p>
        </div>
      </main>
    </b-container>
    <LoadingCard :is-loading="loading.isLoading" :message="loading.message"/>
    <ChooseBusinessType></ChooseBusinessType>
  </div>
</template>

<script>
  import LoadingCard from "@/components/LoadingCard";
  import ChooseBusinessType from "@/components/ChooseBusinessType";

  export default {
  name: 'App',
  components:{ChooseBusinessType, LoadingCard},
    data() {
      return {
      }
    },
    created() {
      this.$store.dispatch('loading/startLoading','Lade Konfigurator ...');
      this.$store.dispatch('orderpage/getOrderPages').then(()=> {
        this.$store.dispatch('loading/stopLoading');
      });

    },
    computed: {
      loading () {
        return this.$store.state.loading
      }
    },

  }
</script>

