<template>
  <b-card class="shadow" :key="tick">
    <template #header>
      <b-avatar v-for="n in steps" :variant="(pointer >= n) ? 'primary' : 'secondary'" :text="n.toString()"
                :key="'avatar'+n" size="3rem" class="mr-2"></b-avatar>
      <b-avatar :variant="'secondary'"
                size="3rem" class="mr-2"><b-icon icon="check2-circle"></b-icon></b-avatar>
      </template>
    <b-card-text v-if="pointer === 1">
      <p class="lead">Bitte vervollständigen Sie Ihre Daten!</p>

        <b-card class="mb-3" bg-variant="light">
            <b-form-group
                label-cols-lg="3"
                label="Rechnungsanschrift"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
            >
              <b-form-group
                  label="Adresse 1"
                  label-for="nested-adress1"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-adress1"
                              v-model="order.billingAddress.addressLine1" :state="order.billingAddress.addressLine1.length > 2"></b-form-input>
              </b-form-group>
              <b-form-group
                  label="Adresse 2"
                  label-for="nested-adress2"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-adress2"
                              v-model="order.billingAddress.addressLine2"></b-form-input>
              </b-form-group>

              <b-form-group
                  label="Postleitzahl:"
                  label-for="nested-zipcode"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-zipcode"
                              v-model="order.billingAddress.zipCode" :state="order.billingAddress.zipCode.length > 4"></b-form-input>
              </b-form-group>

              <b-form-group
                  label="Ort:"
                  label-for="nested-city"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-city"
                              v-model="order.billingAddress.cityName" :state="order.billingAddress.cityName.length > 2"></b-form-input>
              </b-form-group>

              <b-form-group
                  label="Land:"
                  label-for="nested-country"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-select id="nested-country"
                              v-model="order.billingAddress.country" :options="countryCodes"></b-form-select>
              </b-form-group>


              <!--- erst wenn Fakturia soweit ist
              <b-form-group
                  label=""
                  label-cols-sm="3"
                  label-align-sm="right"
                  class="mb-0"
                  v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox
                    id="checkbox-1"
                    v-model="deliveryAdress"
                    name="checkbox-1"
                    @change="() => {
                   if(typeof order.shippingAddress === 'undefined')
                    { order.shippingAddress = JSON.parse(JSON.stringify(order.billingAddress)); tick++}
                    else
                    { delete order.shippingAddress; tick++ }
                     }"
                    :value="true"
                    :unchecked-value="false"
                    :aria-describedby="ariaDescribedby"
                >
                  Abweichende Lieferanschrift <span class="text-muted small">(nur nötig, wenn Hardwware bestellt wird)</span>
                </b-form-checkbox>
              </b-form-group>


              ---->
            </b-form-group>
          </b-card>

        <b-card class="mb-3" bg-variant="light" v-if="typeof order.shippingAddress !== 'undefined'">
            <b-form-group
                label-cols-lg="3"
                label="Lieferanschrift"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
            >

              <b-form-group
                  label="Adresse 1"
                  label-for="nested-adress1"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-adress1"
                              v-model="order.shippingAddress.addressLine1"></b-form-input>
              </b-form-group>
              <b-form-group
                  label="Adresse 2"
                  label-for="nested-adress2"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-adress2"
                              v-model="order.shippingAddress.addressLine2"></b-form-input>
              </b-form-group>

              <b-form-group
                  label="Postleitzahl:"
                  label-for="nested-zipcode"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-zipcode"
                              v-model="order.shippingAddress.zipCode"></b-form-input>
              </b-form-group>

              <b-form-group
                  label="Ort:"
                  label-for="nested-city"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-city"
                              v-model="order.shippingAddress.cityName"></b-form-input>
              </b-form-group>

              <b-form-group
                  label="Land:"
                  label-for="nested-country"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-select id="nested-country"
                               v-model="order.shippingAddress.country" :options="countryCodes"></b-form-select>
              </b-form-group>

            </b-form-group>
          </b-card>

      <b-card class="mb-3" bg-variant="light">
        <b-form-group
            label-cols-lg="3"
            label="Wie möchten Sie bezahlen?"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
        >
          <b-form-group v-slot="{ ariaDescribedby }" buttons>
            <b-form-radio-group buttons stacked v-model="order.paymentMethod" @change="order.paymentRequest.paymentMethod = order.paymentMethod" button-variant="outline-primary">
            <b-form-radio :aria-describedby="ariaDescribedby" class="text-left"  name="creditcard" value="CREDITCARD" style="min-height: 96px; "><b-icon :icon="(order.paymentMethod === 'CREDITCARD') ? 'check-circle' : 'circle'"></b-icon> <img src="../assets/creditcards.png" style="max-width: 87%;"></b-form-radio>
            <b-form-radio :aria-describedby="ariaDescribedby" class="mt-1 text-left" name="paypal" value="PAYPAL" @change="order.paymentRequest.paymentMethod = order.paymentMethod" style="min-height: 96px;"><b-icon :icon="(order.paymentMethod === 'PAYPAL') ? 'check-circle' : 'circle'"></b-icon> <img src="../assets/paypal.png" style="max-width: 87%; max-height: 84px;"></b-form-radio>
            <b-form-radio v-if="paymentMethods.includes('SEPA_DEBIT')" :aria-describedby="ariaDescribedby" class="mt-1 text-left align-text-center" name="SEPA_DEBIT" value="SEPA_DEBIT" @change="order.paymentRequest.paymentMethod = order.paymentMethod"><b-icon :icon="(order.paymentMethod === 'SEPA_DEBIT') ? 'check-circle' : 'circle'"></b-icon> <img src="../assets/sepa.png" style="max-width: 100%; max-height: 84px;"></b-form-radio>

            </b-form-radio-group>
          </b-form-group>
        </b-form-group>
        <p><small>Sie können Ihre Zahlungsmethode ab der zweiten Rechnung in Ihren Einstellungen ändern. Für Bestandskunden bieten wir neben Kreditkarte auch SEPA-Lastschrift an.</small></p>

      </b-card>

        <b-card vclass="mb-3" bg-variant="light">
          <b-form-group
              label-cols-lg="3"
              label="Ihre Kontaktdaten"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-0"
          >


            <b-form-group
                label="Name"
                label-for="nested-name"
                label-cols-sm="3"
                label-align-sm="right"
            >
              <b-input-group id="nested-anrede" class="mb-2">
                <template #prepend>
                  <b-form-select v-model="order.salutation" :options="[{ value: 'null', text: 'Anrede' },{ value: 'MR', text: 'Herr' },{ value: 'MRS', text: 'Frau' }]" :state="customer.data.salutation !== null"></b-form-select>
                </template>
                <b-form-input v-model="order.firstName" aria-label="First name" placeholder="Vorname" :state="order.firstName !== null"></b-form-input>
                <b-form-input v-model="order.lastName" aria-label="Last name" placeholder="Nachname" :state="order.lastName !== null"></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group
                label="Firma:"
                label-for="nested-firma"
                label-cols-sm="3"
                label-align-sm="right"
            >
              <b-form-input id="nested-city" v-model="order.companyName" :state="order.companyName !== null"></b-form-input>
            </b-form-group>

            <b-form-group
                label="E-Mail:"
                label-for="nested-email"
                label-cols-sm="3"
                label-align-sm="right"
            >
              <b-form-input id="nested-email" v-model="order.email" type="email" :state="/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(order.email)"></b-form-input>
            </b-form-group>

            <b-form-group
                label="Telefon:"
                label-for="nested-telefon"
                label-cols-sm="3"
                label-align-sm="right"
            >
              <b-form-input id="nested-telefon" v-model="order.phone1" type="tel" :state="/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$/.test(order.phone1)"></b-form-input>
            </b-form-group>
          </b-form-group>
        </b-card>
    </b-card-text>

    <b-card-text v-else-if="pointer === 2">

      <p class="lead">Bitte geben Sie Ihre Zahlungsinformationen an und schließen Ihre Bestellung ab!</p>

      <h2>Zusammenfassung {{responseData.fakturia.orderNumber}}</h2>
      <p class="lead">{{responseData.fakturia.companyName}}, {{responseData.fakturia.billingAddress.addressLine1}}, {{responseData.fakturia.billingAddress.zipCode}} {{responseData.fakturia.billingAddress.cityName}}</p>
      <b-row class="mb-0">
        <b-col><PriceTable :items="calculatedItems" :regular-items="calculatedRegularItems" title='Abo-Artikel <span class="text-muted">(wiederkehrende Zahlungen pro Monat)</span>' type="RECURRING" :discounted="couponValidationResult.discountConfig"></PriceTable></b-col>
        <b-col><PriceTable :items="calculatedItems" :regular-items="calculatedRegularItems" title="Einmalige Zahlungen" type="ONETIME" :discounted="couponValidationResult.discountConfig" /></b-col>
      </b-row>

      <h5 class="text-right mb-4">Gesamtbetrag der ersten Rechnung: {{formatter.format(amountFirstInvoice)}}</h5>



      <h2 class="mt-3">Bezahlung</h2>
      <p class="lead">Geben Sie hier Ihre Zahlungsinformationen ein.</p>
      <StripeElementPayment v-if="showStripe" ref="paymentRef"
                                :pk="stripePublicKey"
                                :elements-options="elementsOptions"
                                :confirm-params="confirmParams"
                                @element-ready="updateElement"></StripeElementPayment>


        <b-card class="mb-3 mt-3" bg-variant="light">
          <h2 >Geschäftsbedingungen</h2>
            <b-form-checkbox
                id="acceptedPrivacy"
                v-model="business"
                name="acceptedPrivacy"
                :state="business"
                :value="true"
                :unchecked-value="false"
            >
              Ich bestätige, als Gewerbetreiber zu handeln und stimme den <a :href="termsUrl" target="_blank">Geschäftsbedingungen</a> zu.
            </b-form-checkbox>
        </b-card>

    </b-card-text>
    <template #footer >
      <div class="row bg-white fixed-bottom p-4 shadow-lg">
        <div class="col text-left">
          <b-button v-if="pointer > 1" href="#" variant="primary" @click="pointer--"><b-icon icon="arrow-left-circle"></b-icon> zurück</b-button>
        </div>
        <div class="col text-right">
          <b-button v-if="pointer < steps + 1" :disabled="disabledContinueButton" href="#" variant="primary"
                    @click="next"><b-icon :icon="(pointer === 1) ? 'arrow-right-circle' : 'cart-check-fill'"></b-icon>{{(pointer === 1) ? ' Zahlungspflichtig bestellen' : ' Bezahlen'}}
          </b-button>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
  import { StripeElementPayment} from '@vue-stripe/vue-stripe';
  import PriceTable from "@/components/PriceTable";

  export default {
    name:"Order",
    props: {
      customer: {},
      contract: {},
      calculatedItems: {},
      calculatedRegularItems: {},
      couponValidationResult: {},
      amountFirstInvoice: {}
    },
    components: {
      PriceTable,
      StripeElementPayment,
    },
    data() {
      return {
        tick: 1,
        pointer: 1,
        steps: 2,
        business: false,
        deliveryAdress: false,
        changePersonalInformation: false,
        reponse: [],
        paymentMethods: ['CREDITCARD','PAYPAL'],
        showStripe: false,
        stripePublicKey: process.env.VUE_APP_STRIPE_PUBLIC,
        elementsOptions: {
          appearance: {}, // appearance options
        },
        confirmParams: {
          return_url: 'https://socialguard.app', // success url
          payment_method_data: {
            billing_details: {
              name: this.customer.data.companyName,
              address: {
                city: null,
                country: null,
                line1: null,
                line2: null,
                postal_code: null,
                state: null
              },
              email: this.customer.data.email,
              phone: this.customer.data.phone1,
            }
          }
        },
        countryCodes: [
          {value:'AF', text:'Afghanistan'},
          {value:'EG', text:'Ägypten'},
          {value:'AL', text:'Albanien'},
          {value:'DZ', text:'Algerien'},
          {value:'AD', text:'Andorra'},
          {value:'AO', text:'Angola'},
          {value:'AI', text:'Anguilla'},
          {value:'AQ', text:'Antarktis'},
          {value:'AG', text:'Antigua und Barbuda'},
          {value:'GQ', text:'Äquatorial Guinea'},
          {value:'AR', text:'Argentinien'},
          {value:'AM', text:'Armenien'},
          {value:'AW', text:'Aruba'},
          {value:'AZ', text:'Aserbaidschan'},
          {value:'ET', text:'Äthiopien'},
          {value:'AU', text:'Australien'},
          {value:'BS', text:'Bahamas'},
          {value:'BH', text:'Bahrain'},
          {value:'BD', text:'Bangladesh'},
          {value:'BB', text:'Barbados'},
          {value:'BE', text:'Belgien'},
          {value:'BZ', text:'Belize'},
          {value:'BJ', text:'Benin'},
          {value:'BM', text:'Bermudas'},
          {value:'BT', text:'Bhutan'},
          {value:'MM', text:'Birma'},
          {value:'BO', text:'Bolivien'},
          {value:'BA', text:'Bosnien-Herzegowina'},
          {value:'BW', text:'Botswana'},
          {value:'BV', text:'Bouvet Inseln'},
          {value:'BR', text:'Brasilien'},
          {value:'IO', text:'Britisch-Indischer Ozean'},
          {value:'BN', text:'Brunei'},
          {value:'BG', text:'Bulgarien'},
          {value:'BF', text:'Burkina Faso'},
          {value:'BI', text:'Burundi'},
          {value:'CL', text:'Chile'},
          {value:'CN', text:'China'},
          {value:'CX', text:'Christmas Island'},
          {value:'CK', text:'Cook Inseln'},
          {value:'CR', text:'Costa Rica'},
          {value:'DK', text:'Dänemark'},
          {value:'DE', text:'Deutschland'},
          {value:'DJ', text:'Djibuti'},
          {value:'DM', text:'Dominika'},
          {value:'DO', text:'Dominikanische Republik'},
          {value:'EC', text:'Ecuador'},
          {value:'SV', text:'El Salvador'},
          {value:'CI', text:'Elfenbeinküste'},
          {value:'ER', text:'Eritrea'},
          {value:'EE', text:'Estland'},
          {value:'FK', text:'Falkland Inseln'},
          {value:'FO', text:'Färöer Inseln'},
          {value:'FJ', text:'Fidschi'},
          {value:'FI', text:'Finnland'},
          {value:'FR', text:'Frankreich'},
          {value:'GF', text:'französisch Guyana'},
          {value:'PF', text:'Französisch Polynesien'},
          {value:'TF', text:'Französisches Süd-Territorium'},
          {value:'GA', text:'Gabun'},
          {value:'GM', text:'Gambia'},
          {value:'GE', text:'Georgien'},
          {value:'GH', text:'Ghana'},
          {value:'GI', text:'Gibraltar'},
          {value:'GD', text:'Grenada'},
          {value:'GR', text:'Griechenland'},
          {value:'GL', text:'GrÃ¶nland'},
          {value:'UK', text:'GroÃŸbritannien'},
          {value:'GB', text:'GroÃŸbritannien (UK)'},
          {value:'GP', text:'Guadeloupe'},
          {value:'GU', text:'Guam'},
          {value:'GT', text:'Guatemala'},
          {value:'GN', text:'Guinea'},
          {value:'GW', text:'Guinea Bissau'},
          {value:'GY', text:'Guyana'},
          {value:'HT', text:'Haiti'},
          {value:'HM', text:'Heard und McDonald Islands'},
          {value:'HN', text:'Honduras'},
          {value:'HK', text:'Hong Kong'},
          {value:'IN', text:'Indien'},
          {value:'ID', text:'Indonesien'},
          {value:'IQ', text:'Irak'},
          {value:'IR', text:'Iran'},
          {value:'IE', text:'Irland'},
          {value:'IS', text:'Island'},
          {value:'IL', text:'Israel'},
          {value:'IT', text:'Italien'},
          {value:'JM', text:'Jamaika'},
          {value:'JP', text:'Japan'},
          {value:'YE', text:'Jemen'},
          {value:'JO', text:'Jordanien'},
          {value:'YU', text:'Jugoslawien'},
          {value:'KY', text:'Kaiman Inseln'},
          {value:'KH', text:'Kambodscha'},
          {value:'CM', text:'Kamerun'},
          {value:'CA', text:'Kanada'},
          {value:'CV', text:'Kap Verde'},
          {value:'KZ', text:'Kasachstan'},
          {value:'KE', text:'Kenia'},
          {value:'KG', text:'Kirgisistan'},
          {value:'KI', text:'Kiribati'},
          {value:'CC', text:'Kokosinseln'},
          {value:'CO', text:'Kolumbien'},
          {value:'KM', text:'Komoren'},
          {value:'CG', text:'Kongo'},
          {value:'CD', text:'Kongo, Demokratische Republik'},
          {value:'HR', text:'Kroatien'},
          {value:'CU', text:'Kuba'},
          {value:'KW', text:'Kuwait'},
          {value:'LA', text:'Laos'},
          {value:'LS', text:'Lesotho'},
          {value:'LV', text:'Lettland'},
          {value:'LB', text:'Libanon'},
          {value:'LR', text:'Liberia'},
          {value:'LY', text:'Libyen'},
          {value:'LI', text:'Liechtenstein'},
          {value:'LT', text:'Litauen'},
          {value:'LU', text:'Luxemburg'},
          {value:'MO', text:'Macao'},
          {value:'MG', text:'Madagaskar'},
          {value:'MW', text:'Malawi'},
          {value:'MY', text:'Malaysia'},
          {value:'MV', text:'Malediven'},
          {value:'ML', text:'Mali'},
          {value:'MT', text:'Malta'},
          {value:'MP', text:'Marianen'},
          {value:'MA', text:'Marokko'},
          {value:'MH', text:'Marshall Inseln'},
          {value:'MQ', text:'Martinique'},
          {value:'MR', text:'Mauretanien'},
          {value:'MU', text:'Mauritius'},
          {value:'YT', text:'Mayotte'},
          {value:'MK', text:'Mazedonien'},
          {value:'MX', text:'Mexiko'},
          {value:'FM', text:'Mikronesien'},
          {value:'MZ', text:'Mocambique'},
          {value:'MD', text:'Moldavien'},
          {value:'MC', text:'Monaco'},
          {value:'MN', text:'Mongolei'},
          {value:'MS', text:'Montserrat'},
          {value:'NA', text:'Namibia'},
          {value:'NR', text:'Nauru'},
          {value:'NP', text:'Nepal'},
          {value:'NC', text:'Neukaledonien'},
          {value:'NZ', text:'Neuseeland'},
          {value:'NI', text:'Nicaragua'},
          {value:'NL', text:'Niederlande'},
          {value:'AN', text:'Niederländische Antillen'},
          {value:'NE', text:'Niger'},
          {value:'NG', text:'Nigeria'},
          {value:'NU', text:'Niue'},
          {value:'KP', text:'Nord Korea'},
          {value:'NF', text:'Norfolk Inseln'},
          {value:'NO', text:'Norwegen'},
          {value:'OM', text:'Oman'},
          {value:'AT', text:'Österreich'},
          {value:'PK', text:'Pakistan'},
          {value:'PS', text:'Palästina'},
          {value:'PW', text:'Palau'},
          {value:'PA', text:'Panama'},
          {value:'PG', text:'Papua Neuguinea'},
          {value:'PY', text:'Paraguay'},
          {value:'PE', text:'Peru'},
          {value:'PH', text:'Philippinen'},
          {value:'PN', text:'Pitcairn'},
          {value:'PL', text:'Polen'},
          {value:'PT', text:'Portugal'},
          {value:'PR', text:'Puerto Rico'},
          {value:'QA', text:'Qatar'},
          {value:'RE', text:'Reunion'},
          {value:'RW', text:'Ruanda'},
          {value:'RO', text:'Rumänien'},
          {value:'RU', text:'Russland'},
          {value:'LC', text:'Saint Lucia'},
          {value:'ZM', text:'Sambia'},
          {value:'AS', text:'Samoa'},
          {value:'WS', text:'Samoa'},
          {value:'SM', text:'San Marino'},
          {value:'ST', text:'Sao Tome'},
          {value:'SA', text:'Saudi Arabien'},
          {value:'SE', text:'Schweden'},
          {value:'CH', text:'Schweiz'},
          {value:'SN', text:'Senegal'},
          {value:'SC', text:'Seychellen'},
          {value:'SL', text:'Sierra Leone'},
          {value:'SG', text:'Singapur'},
          {value:'SK', text:'Slowakei -slowakische Republik-'},
          {value:'SI', text:'Slowenien'},
          {value:'SB', text:'Solomon Inseln'},
          {value:'SO', text:'Somalia'},
          {value:'GS', text:'South Georgia, South Sandwich Isl.'},
          {value:'ES', text:'Spanien'},
          {value:'LK', text:'Sri Lanka'},
          {value:'SH', text:'St. Helena'},
          {value:'KN', text:'St. Kitts Nevis Anguilla'},
          {value:'PM', text:'St. Pierre und Miquelon'},
          {value:'VC', text:'St. Vincent'},
          {value:'KR', text:'Süd Korea'},
          {value:'ZA', text:'Südafrika'},
          {value:'SD', text:'Sudan'},
          {value:'SR', text:'Surinam'},
          {value:'SJ', text:'Svalbard und Jan Mayen Islands'},
          {value:'SZ', text:'Swasiland'},
          {value:'SY', text:'Syrien'},
          {value:'TJ', text:'Tadschikistan'},
          {value:'TW', text:'Taiwan'},
          {value:'TZ', text:'Tansania'},
          {value:'TH', text:'Thailand'},
          {value:'TP', text:'Timor'},
          {value:'TG', text:'Togo'},
          {value:'TK', text:'Tokelau'},
          {value:'TO', text:'Tonga'},
          {value:'TT', text:'Trinidad Tobago'},
          {value:'TD', text:'Tschad'},
          {value:'CZ', text:'Tschechische Republik'},
          {value:'TN', text:'Tunesien'},
          {value:'TR', text:'Türkei'},
          {value:'TM', text:'Turkmenistan'},
          {value:'TC', text:'Turks und Kaikos Inseln'},
          {value:'TV', text:'Tuvalu'},
          {value:'UG', text:'Uganda'},
          {value:'UA', text:'Ukraine'},
          {value:'HU', text:'Ungarn'},
          {value:'UY', text:'Uruguay'},
          {value:'UZ', text:'Usbekistan'},
          {value:'VU', text:'Vanuatu'},
          {value:'VA', text:'Vatikan'},
          {value:'VE', text:'Venezuela'},
          {value:'AE', text:'Vereinigte Arabische Emirate'},
          {value:'US', text:'Vereinigte Staaten von Amerika'},
          {value:'VN', text:'Vietnam'},
          {value:'VG', text:'Virgin Island (Brit.)'},
          {value:'VI', text:'Virgin Island (USA)'},
          {value:'WF', text:'Wallis et Futuna'},
          {value:'BY', text:'Weissrussland'},
          {value:'EH', text:'Westsahara'},
          {value:'CF', text:'Zentralafrikanische Republik'},
          {value:'ZW', text:'Zimbabwe'},
          {value:'CY', text:'Zypern'}
        ],
        formatter: new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }),
        order: {
          salutation: this.customer.data.salutation,
          firstName: this.customer.data.firstName,
          lastName: this.customer.data.lastName,
          companyName: this.customer.data.companyName,
          phone1: this.customer.data.phone1,
          phone2: this.customer.data.phone2,
          telefax: this.customer.data.telefax,
          mobile: this.customer.data.mobile,
          email: this.customer.data.email,
          birthdate: null,
          euTaxId: null,
          taxNumber: null,
          bankAccountIban: null,
          bankAccountBic: null,
          bankAccountOwner: null,
          billingAddress: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            zipCode: "",
            cityName: "",
            state: "",
            country: "DE"
          },
          paymentMethod: "CREDITCARD",
          orderTemplatePageId: this.$store.getters['orderpage/getCurrentOrderpage'],
          ipAddress: this.customer.data.ip,
          referer: "string",
          orderReference: "",
          orderItems: [],
          customerType: "BUSINESS",
          orderComment: "",
          sepaMandateAgreement: false,
          orderCurrency: "EUR",
          paymentRequest: {
            paymentMethod: "CREDITCARD",
            //requestDate: "2023-03-08T15:17:34.771Z",
            internalTransactionNumber: "",
            currency: "EUR",
            amountRequested: this.amountFirstInvoice,
            amountConfirmed: 0,
            //confirmationDate: "2023-03-08T15:17:34.771Z",
            provider: "STRIPE",
            providerReferenceId: "",
            authorizationOnly: false,
            pspCustomerId: "",
            pspCardId: "",
            pspChargeId: ""
          }
        }
      }
    },
    created() {
      this.$gtag.event('begin_checkout',{});
      if (this.amountFirstInvoice > 100) {
        this.paymentMethods.push('SEPA_DEBIT')
        //this.order.paymentMethod = 'PAYPAL'; // CREDITCARD
        //this.order.paymentRequest.paymentMethod = 'PAYPAL' // CREDITCARD
      }

    },
    computed: {
      disabledContinueButton() {
        return !this.validate(this.pointer);
      },
      responseData() {
        return this.response
      },
      termsUrl() {
        return this.$store.getters['orderpage/showOrderpageById'](this.$store.getters['orderpage/getCurrentOrderpage']).termsUrl
      }
      },
    methods: {
      next() {
        if (this.pointer === 1) {
          this.onSubmit()
        } else if (this.pointer ===2) {
          this.pay();
        }
      },
      pay () {
        this.$gtag.purchase({
          "transaction_id": this.response.fakturia.orderNumber,
          "value": this.amountFirstInvoice
        })

        this.$refs.paymentRef.submit();
      },
      validate(pointer) {
        let out;
        switch (pointer) {
          case 1:
            out = this.order.billingAddress.addressLine1.length > 2 &&
                this.order.billingAddress.zipCode.length > 4 &&
                this.order.billingAddress.cityName.length > 2
            break;
          case 2:
            out = this.business
            break;
          default:
        }
        return out;
      },
      onSubmit() {
        this.$store.dispatch('loading/startLoading','Validiere Bestellung');
        this.createOrder().then(response =>{
          this.$store.dispatch('loading/stopLoading')
          this.response = response;
          if (typeof response.stripe.client_secret !== 'undefined') {
            this.pointer++;
            this.confirmParams = {
              return_url: this.getLink(), // success url
                  payment_method_data: {
                billing_details: {
                  name: this.order.companyName,
                      address: {
                        city: this.order.billingAddress.cityName,
                        country: this.order.billingAddress.country,
                        line1: this.order.billingAddress.addressLine1,
                        line2: this.order.billingAddress.addressLine2,
                        postal_code: this.order.billingAddress.zipCode,
                        state: this.order.billingAddress.state,
                  },
                  email: this.order.email,
                  phone: this.order.phone1,
                }
              }}
            this.elementsOptions.clientSecret = response.stripe.client_secret;
            this.showStripe = true;
            this.tick++;
          }
        })
      },
      updateElement() {
        this.$refs.paymentRef.update({fields: {
            billingDetails: 'never'
          }});
      },
      getLink(){
        return window.location.origin+"/#"+this.getRoute();
      },
      getRoute(){
        return "/confirmation/"+this.response.fakturia.orderNumber;
      },
      createOrder() {
        return new Promise((resolve, reject) => {
          const header = this.authHeader();
          header['Content-Type'] = 'application/json';

          let orderItems = []
          this.contract.itemList.forEach(item => {
            if (item.quantity >0) {
              orderItems.push({
                quantity: item.quantity,
                itemType: item.itemType,
                name: item.item.name,
                itemNumber: item.item.itemNumber,
                description: item.item.description,
                unit: item.item.unit
              });
            }
          })

          this.contract.addonList.forEach(item => {
            if (item.quantity >0) {
              orderItems.push({
                quantity:item.quantity,
                itemType:item.itemType,
                name:item.item.name,
                itemNumber:item.item.itemNumber,
                description:item.item.description,
                unit:item.item.unit
              });
            }
          })


          this.order.orderItems = orderItems;
          if (this.customer.coupon.length > 1 ) this.order.couponCode = this.customer.coupon;

          let senddata = {payload: this.order, amountFirstInvoice: this.amountFirstInvoice, successurl: 'https://socialguard.app'};

          const requestOptions = {
            method: 'POST',
            headers: header,
            body: JSON.stringify(senddata)
          };

          fetch(process.env.VUE_APP_API_SERVER + `?function=FakturiaCreateOrder`, requestOptions)
              .then(this.handleResponse)
              .then(data => {
                resolve(data)
              })
              .catch(error => {
                reject(error)
              });
        })
      }
    }
  }
</script>

<style scoped>

</style>