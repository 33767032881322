export const loading = {
  namespaced: true,
  state: {
    isLoading: false,
    message: ''
  },
  actions: {
    startLoading({ commit }, message) {
      commit('startLoading', message);
    },
    stopLoading({ commit }) {
      commit('stopLoading');
    }
  },
  mutations: {
    startLoading(state, message) {
      state.isLoading = true;
      state.message = message;
    },
    stopLoading(state) {
      state.isLoading = false;
      state.message = '';
    }
  }
}
