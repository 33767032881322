import Vue from 'vue'
import Router from 'vue-router'
import MainContent from '@/components/MainContent'
import Offering from '@/components/Offering'
import Order from '@/components/Order'
import OrderConfirmation from '@/components/OrderConfirmation'
Vue.use(Router);

const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'start',
      component: MainContent,
    },
    {
      path: '/pid/:coupon',
      component: MainContent,
    },
    {
      path: '/order',
      name: 'order',
      component: Order,
      props: true
    },
    {
      path: '/confirmation/:order',
      component: OrderConfirmation,
    },
    {
      path: '/cus/:customer/con/:contract/',
      component: Offering,
    }]});



export default router;