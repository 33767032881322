<template>
  <b-card class="shadow">
    <template #header>
      <b-avatar v-for="n in steps" :variant="(pointer >= n) ? 'primary' : 'secondary'" :text="n.toString()"
                :key="'avatar'+n" size="3rem" class="mr-2"></b-avatar>
    </template>
    <Transition mode="out-in">
      <b-card-text v-if="pointer === 1">
        <p class="lead">Wo möchten Sie Socialguard einsetzen?</p>
        <b-row>
          <div class="col-lg-6 p-1">
            <div :class="(customer.cat.includes('Spielhalle o. Wettannahme') > 0) ? 'bg-info p-2 rounded' : 'bg-secondary rounded p-2'">
              <h3 class="mb-2 text-dark">Spielhalle o. Wettannahme</h3>
              <img src="../assets/casino.png"><br>
              <b-form-checkbox v-model="customer.cat" :button-variant="(customer.cat.includes('Spielhalle o. Wettannahme') ? 'success' : 'primary')" name="check-button" value="Spielhalle o. Wettannahme" button>
                {{customer.cat.includes('Spielhalle o. Wettannahme') ? 'ausgewählt' : 'auswählen'}}
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-lg-6 p-1">
            <div :class="(customer.cat.includes('Gastronomie')) ? 'bg-info rounded p-2' : 'bg-secondary rounded p-2'">
              <h3 class="mb-2 text-dark">Gastronomie</h3>
              <img src="../assets/gaststaett.png"><br>
              <b-form-checkbox v-model="customer.cat" :button-variant="(customer.cat.includes('Gastronomie') ? 'success' : 'primary')" name="check-button" value="Gastronomie" button>
                {{customer.cat.includes('Gastronomie') ? 'ausgewählt' : 'auswählen'}}
              </b-form-checkbox>
            </div>
          </div>
        </b-row>
      </b-card-text>
      <b-card-text v-else-if="pointer === 2">
        <div v-for="(cat,j) in customer.cat" class="mb-3" :key="j">
          <h5>{{cat}}</h5>
          <p class="lead">Folgende Pakete stehen zur Verfügung. Bitte wählen Sie durch Angabe der Anzahl Ihrer Standorte!</p>
          <div v-for="(item,i) in orderpage.contractTemplate.itemList" class="mb-3" :key="i">
            <b-row v-if="cat === item.item.customFields.Kategorie1">
              <div class="col p-1">
                <div :class="(item.quantity > 0) ? 'bg-info p-2 rounded' : 'bg-secondary rounded p-2'">
                  <h3>{{item.item.name}}</h3>
                  <span v-html="item.item.customFields.HTMLdescription"></span>
                  <b-form-spinbutton v-model="item.quantity" :id="item.item.itemNumber+'-count'" size="lg" placeholder="--"
                                     class="mb-2" min="0" max="100" step="1"></b-form-spinbutton>
                </div>
              </div>
            </b-row>
          </div>
        </div>
      </b-card-text>
      <b-card-text v-else-if="pointer === 3">
        <p class="lead">Sofern vor Ort ein PC mit Windows und Internetanschluss zur Verfügung steht, empfehlen wir einen ID Scanner. Sofern die Sperrabfrage via Smarthone erfolgen soll, ist kein ID Scanner notwendig. Bitte geben Sie die Anzahl der benötigten Geräte an (max. 1/Vertrag).</p>
        <div v-for="(item,i) in orderpage.contractTemplate.addonList" class="mb-3" :key="i">
          <b-row >
            <div class="col p-1">
              <div :class="(item.quantity > 0) ? 'bg-info p-2 rounded' : 'bg-secondary rounded p-2'">
                <h3>{{item.item.name}}</h3>
                <img v-if="typeof item.item.customFields.image !== 'undefined'" :src="item.item.customFields.image" style="max-width: 100%;">
                <span v-html="item.item.customFields.HTMLdescription"></span>
                <b-form-spinbutton v-model="item.quantity" :id="item.item.itemNumber+'-count'" :disabled="!item.orderQuantityChange" size="lg" placeholder="--"
                                   class="mb-2" min="0" :max="(item.item.customFields.nolimit === null || !item.item.customFields.nolimit) ? maxAddon+item.quantity+1 : item.orderMaxQuantity	" step="1"></b-form-spinbutton>
              </div>
            </div>
          </b-row>
        </div>
      </b-card-text>
      <b-card-text v-else-if="pointer === 4">
        <div>
          <b-card bg-variant="light">
            <b-form-group
                label-cols-lg="3"
                label="Ihre Kontaktdaten"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
            >


              <b-form-group
                  label="Name"
                  label-for="nested-name"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-input-group id="nested-anrede" class="mb-2">
                  <template #prepend>
                    <b-form-select v-model="customer.data.salutation" :options="[{ value: 'null', text: 'Anrede' },{ value: 'MR', text: 'Herr' },{ value: 'MRS', text: 'Frau' }]" :state="customer.data.salutation !== null"></b-form-select>
                  </template>
                  <b-form-input v-model="customer.data.firstName" aria-label="First name" placeholder="Vorname" :state="customer.data.firstName !== null"></b-form-input>
                  <b-form-input v-model="customer.data.lastName" aria-label="Last name" placeholder="Nachname" :state="customer.data.lastName !== null"></b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-group
                  label="Firma:"
                  label-for="nested-firma"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-firma" v-model="customer.data.companyName" :state="customer.data.companyName !== null"></b-form-input>
              </b-form-group>

              <b-form-group
                  label="E-Mail:"
                  label-for="nested-email"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-email" v-model="customer.data.email" type="email" :state="/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(customer.data.email)"></b-form-input>
              </b-form-group>

              <b-form-group
                  label="Telefon:"
                  label-for="nested-telefon"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-input id="nested-telefon" v-model="customer.data.phone1" type="tel" :state="/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$/.test(customer.data.phone1)"></b-form-input>
              </b-form-group>

              <b-form-group
                  label=""
                  label-for="nested-agb"
                  label-cols-sm="3"
                  label-align-sm="right"
              >
                <b-form-checkbox
                  id="acceptedPrivacy"
                  v-model="customer.data.acceptedPrivacy"
                  name="acceptedPrivacy"
                  :state="customer.data.acceptedPrivacy"
                  :value="true"
                  :unchecked-value="false"
              >
                  Ich stimme den <a href="https://socialguard.app/datenschutzerklaerung/" target="_blank">Datenschutzbedingungen</a> zu.
              </b-form-checkbox>
                <b-form-checkbox
                    id="acceptedOptin"
                    v-model="customer.data.acceptedOptin"
                    name="acceptedOptin"
                    :state="customer.data.acceptedOptin"
                    :value="true"
                    :unchecked-value="false"
                >
                  Ich stimme dem Erhalt von Produktinformationen und Angeboten zu.
                </b-form-checkbox>
                <b-form-checkbox
                    id="acceptedBusiness"
                    v-model="customer.data.accpetedBusiness"
                    name="acceptedBusiness"
                    :state="customer.data.accpetedBusiness"
                    :value="true"
                    :unchecked-value="false"
                >
                  Ich bestätige ausdrücklich <b>gewerblich</b> aktiv zu sein.
                </b-form-checkbox>
              </b-form-group>
            </b-form-group>
          </b-card>
        </div>
      </b-card-text>
      <b-card-text v-else-if="pointer === 5">
        <LeadCreator :customer="customer" :contract-data="orderpage.contractTemplate"></LeadCreator>
      </b-card-text>
    </Transition>
    <template #footer>
      <div class="row bg-white fixed-bottom p-4 shadow-lg">
        <div class="col text-left">
          <b-button v-if="pointer > 1" href="#" variant="primary" @click="pointer--"><b-icon icon="arrow-left-circle"></b-icon> zurück</b-button>
        </div>
        <div class="col"><p class="text-danger font-italic"><small><b>Nur gewerbliche Anfragen!</b></small></p></div>
        <div class="col text-right">
          <b-button v-if="pointer < steps" :disabled="disabledContinueButton" href="#" variant="primary"
                    @click="() => { trackConfig(pointer);pointer++}"><b-icon icon="arrow-right-circle"></b-icon> weiter
          </b-button>
        </div>
      </div>
    </template>
    <b-modal id="modal-xl" size="xl" title="Richtig gelesen?" :visible="noBusiness" no-close-on-esc no-close-on-backdrop hide-footer hide-header-close><p class="lead mb-4">Sie haben keine Firma und haben stattdessen Fragen zur Spielersperre?</p> <p class="lead mb-4"><b>Wenden Sie sich stattdessen ans Spielerportal! Wir können Ihnen nicht helfen!</b></p> <a href="https://socialguard.app/oasis/sperre/" class="btn btn-success btn-block">Zum Spielerportal</a></b-modal>
  </b-card>
</template>
<script>
  import LeadCreator from "@/components/LeadCreator";
  export default {
    name:'MainContent',
    components:{LeadCreator},
    data() {
      return {
        pointer:1,
        steps:5,
        itemMatrix:{
          spielo:{
            basic:'SG23',
            premium:'SG18'
          },
          gastro:{
            basic:'SG17'
          }
        },
        customer:{
          cat:[],
          coupon: '',
          data: {
            salutation: null,
            firstName: null,
            lastName: null,
            companyName: null,
            phone1: null,
            phone2: null,
            telefax: null,
            mobile: null,
            email: null,
            acceptedPrivacy: false,
            acceptedOptin: false,
            accpetedBusiness: false,
            ip: null
          }
        }
      }
    },
    created() {
      if (typeof this.$route.params.coupon !== 'undefined') this.customer.coupon = this.$route.params.coupon


      fetch('https://api.ipify.org?format=json', {
        method:'GET',
        headers:{'Content-Type':'application/json'},
      }).then(this.handleResponse)
          .then(response => {
            this.customer.data.ip = response.ip;
          }).catch(() => {
        fetch('https://ipapi.co/json/', {
          method:'GET',
          headers:{'Content-Type':'application/json'},
        }).then(this.handleResponse)
            .then(response => {
              this.customer.data.ip = response.ip;
            })
      })
    },
    computed:{
      disabledContinueButton() {
        return !this.validate(this.pointer);
      },
      orderpage() {
        return this.getOrderpage()
      },
      maxAddon() {
        return this.getQuantity() - this.getAddonQuantity();
      },
      noBusiness() {
        return (this.customer.data.companyName !== null) ?
            (this.customer.data.companyName.toLowerCase() === 'keine' ||
            this.customer.data.companyName.toLowerCase() === 'kein' ||
            this.customer.data.companyName.toLowerCase() === 'privat')
            : false;
      }
    },
    methods:{
      trackConfig (step) {
        this.$gtag.event('config', {
          'event_category': 'checkout',
          'event_label': '',
          'value': step
          })
      },
      validate(pointer) {
        let out;
        switch (pointer) {
          case 1:
            out = this.customer.cat.length > 0
            break;
          case 2:
              out = this.getQuantity() > 0
            break;
          case 3:
            out = true
            break;
          case 4:
            out = this.customer.data.salutation !== null &&
                this.customer.data.firstName !== null &&
                this.customer.data.lastName !== null &&
                this.customer.data.companyName !== null &&
                this.customer.data.companyName.toLowerCase() !== 'keine' &&
                this.customer.data.companyName.toLowerCase() !== 'kein' &&
                this.customer.data.companyName.toLowerCase() !== 'privat' &&
                this.customer.data.companyName.toLowerCase() !== '-' &&
                this.customer.data.companyName.toLowerCase() !== '' &&
                this.customer.data.email !== null &&
                this.customer.data.phone1 !== null &&
                this.customer.data.acceptedPrivacy !== false &&
                this.customer.data.acceptedOptin !== false
            break;
          default:
        }
        return out;
      },
      getOrderpage() {
        return this.$store.getters['orderpage/showOrderpageById'](this.$store.getters['orderpage/getCurrentOrderpage'])
      },
      getQuantity() {
        let out = 0;
        this.getOrderpage().contractTemplate.itemList.forEach(item => {
          out += item.quantity
        })
        return out;
      },
      getAddonQuantity() {
        let out = 0;
        this.getOrderpage().contractTemplate.addonList.forEach(item => {
            if (item.item.customFields.nolimit === null || !item.item.customFields.nolimit) out += item.quantity
        })
        return out;
      }
    }
  }
</script>
