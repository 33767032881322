import {authHeader} from '../_helpers';
import {handleResponse} from '../_helpers';


export const orderpageService = {
  getOrderPages
};


function getOrderPages() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(process.env.VUE_APP_API_SERVER + `?function=FakturiaOrderTemplates`, requestOptions)
      .then(handleResponse)
      .then(data => {
        return data;
      });
}
