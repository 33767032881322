<template>
  <div>
  <h5 class="mt-3" v-html="title"></h5>
  <div class="text-muted text-right">* zzgl. MwSt.</div>
  <b-table striped hover :items="items.items.filter(item => item.itemType === type && item.quantity > 0)" :fields="fields" class="w-100" responsive="sm">
    <template #cell(name)="data">
      <b class="text-dark">{{data.item.name}}</b><br>
      {{ data.item.description}}

    </template>
    <template #cell(quantity)="data">
      {{data.item.quantity}}
    </template>
    <template #cell(priceNet)="data">
      <span v-if="getRegularItemPrice(data.item.itemNumber).priceNet === data.item.priceNet">
      {{ formatter.format(data.item.priceNet) }}*
        </span>
      <div v-else>
        <h5 class="text-danger mb-0">{{ formatter.format(data.item.priceNet) }}*</h5>
        <span v-if="type === 'RECURRING'" class="text-muted text-sm-right">
          <small v-if="discounted.validityPeriod !== null">ab {{discounted.validityPeriod+1}}. {{translations[discounted.validityUnit]}} {{formatter.format(getRegularItemPrice(data.item.itemNumber).priceNet)}}</small>
          <small v-else>statt {{formatter.format(getRegularItemPrice(data.item.itemNumber).priceNet)}}</small></span>
        <span v-else class="text-muted text-sm-right">
        <small>statt {{formatter.format(getRegularItemPrice(data.item.itemNumber).priceNet)}}</small></span>
      </div>
    </template>
    <template #cell(priceTotalNet)="data">
            <span v-if="getRegularItemPrice(data.item.itemNumber).priceTotalNet === data.item.priceTotalNet">
      {{ formatter.format(data.item.priceTotalNet) }}*
        </span>
      <div v-else>
      <h5 class="text-danger mb-0">{{ formatter.format(data.item.priceTotalNet) }}*</h5>
      <span v-if="type === 'RECURRING'" class="text-muted text-sm-right">
        <small v-if="discounted.validityPeriod !== null">ab {{discounted.validityPeriod+1}}. {{translations[discounted.validityUnit]}} {{formatter.format(getRegularItemPrice(data.item.itemNumber).priceTotalNet)}}</small>
        <small v-else>statt {{formatter.format(getRegularItemPrice(data.item.itemNumber).priceTotalNet)}}</small></span>
      <span v-else class="text-muted text-sm-right">
        <small>statt {{formatter.format(getRegularItemPrice(data.item.itemNumber).priceTotalNet)}}</small></span>
      </div>
    </template>
  </b-table>
  <div class="text-right mb-5">
    Gesamt (netto): {{formatter.format((type === 'RECURRING') ? items.sumRecurNet : items.sumOnetimeNet)}}<br><span class="text-muted font-weight-light">+19% MwSt. {{formatter.format((type === 'RECURRING') ? items.sumRecurGross - items.sumRecurNet : items.sumOnetimeGross - items.sumOnetimeNet)}} </span><br>
    <b>Gesamt (brutto): {{formatter.format((type === 'RECURRING') ? items.sumRecurGross : items.sumOnetimeGross)}} </b></div>
  </div>
</template>
<script>
  export default {
    name:'PriceTable',
    props:{
      title:{},
      items:{},
      regularItems:{},
      type:{},
      discounted: {
      }
      },
    data() {
      return {
        fields: [{ key: 'name', label: 'Artikel' }, { key: 'quantity', label: 'Anzahl' }, { key: 'priceNet', label: 'Einzelpreis' },{ key: 'priceTotalNet', label: 'Gesamtpreis' },],
        formatter: new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }),
        translations: {
          WEEK: 'Woche',
          YEAR: 'Jahr',
          MONTH: 'Monat'
        },
      }
    },
    methods: {
      getRegularItemPrice(itemNumber) {
        return this.regularItems.items.find(item => item.itemNumber === itemNumber)
      }
    }
      }
</script>
