<template>
  <b-modal v-model="isLoading" id="loading-modal" body-class="text-center p-5" size="sm" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc>

      <b-spinner variant="primary" label="Spinning" style="width: 5rem; height: 5rem;"></b-spinner>
      <p class="lead text-primary">{{message}}</p>

  </b-modal>
</template>
<script>
  export default {
    name:'LoadingCard',
    props:['message','isLoading'],
    data(){
      return {
        loading: true
      }
    }
  }
</script>
