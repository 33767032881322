<template>
  <div>
    <h5 Class="display-4">Sind Ihre Kontaktdaten korrekt?</h5>
    <p class="lead">Bitte prüfen Sie insbesondere Ihre E-Mailadresse.</p>
    <b-table stacked :items="[customer.data]" :fields="[{key: 'firstName', label: 'Vorname'},{key: 'lastName', label: 'Nachname'},{key: 'companyName', label: 'Firma'},{key: 'phone1', label: 'Telefon'},{key: 'email', label: 'E-Mail'}]"></b-table>
    <p class="text-muted m-3">Unser Angebot richtet sich ausschließlich an Automatenaufsteller, Spielhallen- und Wettbürobetreiber. Sollten Sie ein privates Anliegen zu Ihrer Spielsperre haben, klicken Sie auf "Sie haben Fragen zu Ihrer Spielersperre".</p>
    <b-button variant="success" class="m-3" size="lg" @click="submit()">Angaben sind korrekt - Angebot ansehen</b-button>
    <a href="https://socialguard.app/oasis/sperre/" class="btn btn-primary btn-lg">Sie haben Fragen zur Spielersperre?</a>
  </div>
</template>

<script>
  export default {
    name:"LeadCreator",
    props: {
      customer: {

      },
      contractData:{

      }
    },
    created() {

    },
    computed: {
      link() {
        return "/"+this.getLink();
      }
    },
    methods: {
      track () {
        this.$gtag.event('generate_lead')
      },
      getLink(){
        return window.location.origin+"/#"+this.getRoute();
      },
      getRoute(){
        return "/cus/"+encodeURIComponent(btoa(JSON.stringify(this.customer)))+"/con/"+encodeURIComponent(btoa(JSON.stringify(this.contractData)));
      },
      submit() {
        this.$store.dispatch('loading/startLoading','Berechne Angebot');
        this.createMauticContact().then(() => {
          this.track();
          this.$router.push(this.getRoute());
          this.$store.dispatch('loading/stopLoading');
        }).catch(() => {
          this.$router.push(this.getLink());
          this.$store.dispatch('loading/stopLoading');
        })
      },
      createMauticContact(){
        return new Promise((resolve, reject) => {
          const header = this.authHeader();
          header['Content-Type'] = 'application/json';
          let senddata = this.customer;
          senddata['link'] = this.getLink();
          const requestOptions = {
            method: 'POST',
            headers: header,
            body: JSON.stringify(senddata)
          };

          fetch(process.env.VUE_APP_API_SERVER + `?function=mautic`, requestOptions)
              .then(this.handleResponse)
              .then(data => {
                resolve(data)
              })
              .catch(error => {
                reject(error)
              });
        })
      }
    }
  }
</script>

<style scoped>

</style>