<template>
  <b-card class="shadow-lg">
    <b-iconstack font-scale="12" class="m-5">
      <b-icon stacked icon="patch-check-fill" variant="success" scale="0.5"></b-icon>
      <b-icon stacked icon="circle" variant="primary"></b-icon>
    </b-iconstack>
    <h4>Vielen Dank für Ihre Bestellung {{$route.params.order}}!</h4>
    <p class="lead mb-3">Sie erhalten in den nächsten Minuten Ihre Zugangsdaten per E-Mail zugestellt.</p>

    <a v-if="isApp()" href="https://go.socialguard.app" class="btn btn-success btn-block">Zurück zum Login</a>


    <h4 class="mt-5 mb-4">Wie geht es weiter?</h4>
    <b-card-group deck>
      <b-card class="shadow"
          border-variant="primary"
          header="Socialguard öffnen"
          header-bg-variant="info"
          header-text-variant="white"
          bg-variant="secondary"
          align="center"
          @click="goToPage('https://go.socialguard.app')"
      >
        <b-card-text>
          <b-icon icon="app" font-scale="5" class="mb-2" variant="info"></b-icon>
          <p>Öffnen Sie Socialguard und loggen sich mit den Zugangsdaten aus der E-Mail ein. Wichtig: Ihr Adminkonto hat Ihre persönliche E-Mailadresse als Nutzernamen.</p>
          <a href="https://go.socialguard.app" class="btn btn-primary" target="_blank"><b-icon icon="app"></b-icon> Socialguard öffnen</a>
        </b-card-text>
      </b-card>
      <b-card class="shadow"
          border-variant="primary"
          header="OASIS einrichten"
          header-bg-variant="info"
          header-text-variant="white"
          bg-variant="secondary"
          align="center"
          @click="goToPage('https://service.socialguard.app/kb/faq.php?id=1')"
      >
        <b-card-text><b-icon icon="book" font-scale="5" class="mb-2" variant="info"></b-icon><p>Um die Sperrabfrage bei OASIS durchzuführen, benötigen Sie eine Verbindung zu der Sperrdatenbank. Erfahren Sie hier, wie Sie die Verbindung herstellen!</p>
          <a href="https://service.socialguard.app/kb/faq.php?id=11" class="btn btn-primary" target="_blank"><b-icon icon="book"></b-icon> Zur Anleitung</a></b-card-text>
      </b-card>
      <b-card class="shadow"
          border-variant="primary"
          header="Socialguard installieren"
          header-bg-variant="info"
          header-text-variant="white"
          bg-variant="secondary"
          align="center"
          @click="goToPage('https://service.socialguard.app/kb/faq.php?cid=6')"
      >
        <b-card-text><b-icon icon="tools" font-scale="5" class="mb-2" variant="info"></b-icon><p>Damit Sie im Tagesgeschäft die Sperrabfrage durchführen können, muss Socialguard auch auf einem lokalen Gerät installiert werden. Hier finden Sie alle Downloads und eine Anleitung zur Installation.</p>
          <p><a href="https://service.socialguard.app/kb/faq.php?id=2" class="btn btn-primary" target="_blank"><b-icon icon="display"></b-icon> Auf Windows PC</a></p>
          <p><a href="https://service.socialguard.app/kb/faq.php?id=53" class="btn btn-primary" target="_blank"><b-icon icon="phone"></b-icon> Auf Android/iOS</a></p>
        </b-card-text>
      </b-card>

      <b-card class="shadow"
          border-variant="primary"
          header="Sie benötigen Hilfe?"
          header-bg-variant="info"
          header-text-variant="white"
          bg-variant="secondary"
          align="center"
          @click="goToPage('https://service.socialguard.app')"
      >
        <b-card-text><b-icon icon="chat-text" font-scale="5" class="mb-2" variant="info"></b-icon><p>In unserem Service-Portal unter service.socialguard.app finden Sie zahlreiche Anleitungen, Hilfestellungen bei Problemen und eine Möglichkeit, direkt Kontakt mit unserem Support aufzunehmen. </p>
          <a href="https://service.socialguard.app" class="btn btn-primary" target="_blank"><b-icon icon="chat-text"></b-icon> Zum Service Portal</a></b-card-text>
      </b-card>

    </b-card-group>
  </b-card>
</template>

<script>
  export default {
    name:"OrderConfirmation",
    methods: {
     goToPage(url){
       window.open(url)
     },
      isApp(){
        return navigator.userAgent.includes('SGScanner Flutter')
      }
   }
  }
</script>

<style scoped>

</style>