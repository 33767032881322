export function authHeader() {
    // return authorization header with jwt user
    let token = process.env.VUE_APP_API_KEY;

    if (token) {
        return { 'api-key': token };
    } else {
        return {};
    }
}

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && (data.message || data['hydra:description'])) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}