import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './_store';
import { authHeader } from './_helpers'
import { handleResponse } from './_helpers'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { StripePlugin } from '@vue-stripe/vue-stripe'; // https://vuestripe.com/
import VueGtag from "vue-gtag";



const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLIC,
  testMode: true,
  stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
  apiVersion: '2020-03-02',
  locale: 'de',
};

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/app.scss'

Vue.config.productionTip = false

Vue.mixin({
  methods:{
    authHeader() {
      return authHeader();
    },
    handleResponse(response) {
      return handleResponse(response)
    }
  }
})

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons)
Vue.use(StripePlugin,options);

Vue.use(VueGtag, {
  config: { id: "G-DW4EDC420V" }
}, router);


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
