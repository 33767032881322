import { orderpageService } from '../_services';

export const orderpage = {
  namespaced: true,
  state: {
    all: [],
    ready: false,
    current: process.env.VUE_APP_CHECKOUT_TEMPLATE,
    type: 'biyear'
  },
  actions: {
    getOrderPages({ commit }) {
      commit('getAllRequest');
      return new Promise((resolve, reject) => {

        orderpageService.getOrderPages()
            .then(
                status => {
                  commit('getAllSuccess', status)
                  resolve(status)
                },
                error => {
                  commit('getAllFailure', error)
                  reject(error)
                }
            );
      });
    },
    setMonthly({ commit }){
      commit('getMonthlyTemp');
    },
    setBiyear({ commit }){
      commit('getBiyearTemp');
    }
  },
  mutations: {
    getAllRequest(state) {
      state.all = { loading: true };
      state.ready = false;
    },
    getAllSuccess(state, status) {
      state.all = status;
      state.ready = true;
    },
    getAllFailure(state, error) {
      state.all = { error };
      state.ready = false;
    },
    getMonthlyTemp(state) {
      state.current = process.env.VUE_APP_CHECKOUT_TEMPLATE_MONTHLY;
      state.type = 'monthly'
    },
    getBiyearTemp(state) {
      state.current = process.env.VUE_APP_CHECKOUT_TEMPLATE;
      state.type = 'biyear'
    }
  },
  getters: {
    showOrderpages(state) {
      return state.all;
    },
    getCurrentOrderpage(state) {
      return state.current;
    },
    getType(state) {
      return state.type;
    },
    showOrderpageById(state) {
      return id => {
        return state.all.find(obj => obj.checkoutPageId === id)
      }
    },
    isLoading(state) {
      return (state.all.loading);
    },
    isReady(state) {
      return (state.ready);
    }
  }
};
